<template>
  <b-modal
    id="modal-apartment-detail"
    ref="refModalApartmentDetail"
    title="Thông tin tòa nhà"
    size="lg"
    scrollable
    :hide-footer="true"
    no-close-on-backdrop
    @show="onOpen"
    @hidden="resetModal"
  >

    <div class="d-flex justify-content-center mb-1">
      <b-spinner
        v-if="isLoading"
        class="text-center"
        variant="secondary"
      />
    </div>

    <div v-if="itemLocal && !isLoading">
      <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary pb-1">
        1. Thông tin tòa nhà
      </div>
      <b-list-group class="">
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              Tên tòa nhà:
            </b-col>
            <b-col class=" ">
              {{ itemLocal.name }}
            </b-col>
          </b-row>
        </b-list-group-item>

        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              Địa chỉ:
            </b-col>
            <b-col class="">
              {{ itemLocal.fullAddress }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              Trạng thái hoạt động:
            </b-col>
            <b-col class="">
              {{ itemLocal.active ? 'Đang hoạt động' : 'Ngừng sử dụng' }}
            </b-col>
          </b-row>
        </b-list-group-item>

      </b-list-group>
      <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary pb-1 pt-2">
        2. Hình ảnh tòa nhà
      </div>

    </div>
    <!-- Body -->

  </b-modal>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BListGroup,
  BListGroupItem,
  BSpinner,
} from 'bootstrap-vue';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import useApartmentDetailModal from './useApartmentDetailModal';

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    BSpinner,

  },
  props: {
    apartmentId: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModalDeleteCashbook,
      itemLocal,
      resetItemLocal,
      resetModal,
      onOpen,
      isLoading,
    } = useApartmentDetailModal(props, emit, refFormObserver);

    return {
      refModalDeleteCashbook,
      itemLocal,
      resetItemLocal,
      resetModal,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      onOpen,
      isLoading,
    };
  },
};
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-good-table.scss";
  .vgt-wrap {
    .vgt-table {
      thead {
        th {
          font-size: 1rem !important;
          font-weight: 500;
        }
      }
      td {
        font-size: 1rem !important;
        font-weight: 500;
      }
    }
  }
  </style>
