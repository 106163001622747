<template>
  <b-modal
    id="modal-apartment"
    ref="refModal"
    title="Tòa nhà"
    cancel-title="Huỷ"
    cancel-variant="outline-secondary"
    size="lg"
    :ok-title="isSubmitting ? 'Đang lưu' : 'Lưu'"
    :ok-disabled="isSubmitting"
    :hide-header-close="true"
    no-close-on-backdrop
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="name"
              rules="required"
            >
              <b-form-group>
                <template v-slot:label>
                  Tên tòa nhà <span class="text-danger">(*)</span>
                </template>
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <feather-icon icon="HomeIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="name"
                    v-model="itemLocal.name"
                    placeholder="CH-01"
                    trim
                    :state="getValidationState(validationContext)"
                  />
                </b-input-group>
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Địa chỉ -->
          <b-col
            cols="12"
            class="mt-2"
          >
            <h5>Thông tin địa chỉ</h5>
          </b-col>
          <b-col md="4">
            <validation-provider
              #default="validationContext"
              name="province"
              rules="required"
            >
              <select-province
                v-model="itemLocal.province"
                required
                :default-value="itemLocal.province"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col md="4">
            <validation-provider
              #default="validationContext"
              name="district"
              rules="required"
            >
              <select-district
                v-model="itemLocal.district"
                required
                :default-value="itemLocal.district"
                :province="itemLocal.province"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col md="4">
            <validation-provider
              #default="validationContext"
              name="ward"
              rules="required"
            >
              <select-ward
                v-model="itemLocal.ward"
                required
                :default-value="itemLocal.ward"
                :district="itemLocal.district"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="address"
              rules="required"
            >
              <b-form-group>
                <template v-slot:label>
                  Địa chỉ chi tiết <span class="text-danger">(*)</span>
                </template>
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <feather-icon icon="MapPinIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="address"
                    v-model="itemLocal.address"
                    placeholder="91 Nguyễn Chí Thanh"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                </b-input-group>
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Active/Inactive -->
          <b-col
            cols="12"
            class="mt-2"
          >
            <b-form-group>
              <b-form-checkbox
                v-model="itemLocal.active"
                name="check-button"
                switch
                inline
              >
                Hoạt động
              </b-form-checkbox>
            </b-form-group>
          </b-col>

          <!-- submit -->
        </b-row>
        <b-row v-if="isTingTong">
          <b-col>
            <number-input
              v-model="itemLocal.paymentDay"
              required
              :default-value="itemLocal.paymentDay"
              label="Ngày thanh toán"
              placeholder="5"
            />
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>

  </b-modal>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormInput,
  BForm,
  BInputGroup,
  BFormCheckbox,
  BRow,
  BCol,
  BInputGroupPrepend,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import SelectProvince from '@/views/components/SelectProvince.vue';
import SelectDistrict from '@/views/components/SelectDistrict.vue';
import SelectWard from '@/views/components/SelectWard.vue';
import NumberInput from '@/views/components/NumberInput.vue';
import useApartmentModal from './useApartmentModal';

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BForm,
    BInputGroup,
    BInputGroupPrepend,
    BCol,
    BRow,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,

    SelectProvince,
    SelectDistrict,
    SelectWard,
    NumberInput,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModal,
      itemLocal,
      resetItemLocal,
      resetModal,
      isSubmitting,
      onSubmit,
      isTingTong,
    } = useApartmentModal(props, emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,
      isTingTong,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    };
  },
};
</script>
